import styles from "./index.module.css";
import SuccessIcon from "../../assets/images/success.svg";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../components/atoms/Loader/Loader";
import ErrorIcon from "../../assets/images/ErrorAlert.svg";
import { APIURL } from "../../constants/apiConstants";

const Resubscribe = () => {
    
    const { state } = useLocation();
    const cvi = state?.cvi; 
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isMessage, setIsMessage] = useState(''); 

    useEffect(() => {
        if (cvi) {
            setIsSuccess(false);
            setIsError(false);
            setIsMessage('');
            setIsLoading(true);
            axios.post(APIURL.REFER_UNSUBSCRIBE, { cvi: cvi, isSubscribe:true })
                .then((response) => {
                    setIsSuccess(true);
                    setIsMessage(response?.data?.successMessage ? response?.data?.successMessage : "You have subscribed successfully.");
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsError(true);
                    const errorMsg = error.response?.data?.errorMessage || error.response?.error;
                    if (errorMsg) {
                        setIsMessage(errorMsg ?? "Something went wrong");
                    }
                        else {
                            setIsMessage("Something went wrong");
                        }
                    });
                }
            }, [cvi])
            
    if (isLoading) {
        return <Loader />
  }
   
    return (
        <div className={`${styles.successMsg}`}>
        <div className={`${styles.successfulBooking}mx-auto max-w-3xl `}>
          <div className="flex flex-col gap-4">
            <div className="col-span-2">
            <div className={`${styles.imageParent}`}>
              {isSuccess && 
              <img
                src={SuccessIcon}
                className={`${styles.successfulImgResubs} text-primary-500 h-12 w-20 mb-4`}
                alt="Success"
              />
              } 
              {isError &&  <img src={ErrorIcon} className={`${styles.errImg} text-primary-500 h-12 w-20 mb-4`} alt="Error"/>
              }
              </div>
              <div className={`${styles.textParent}`}>
                <h1 className={`${styles.successfulHeading}text-primary-500 font-bold  md:text-center md:text-2xl md:mx-auto`}>
                    {isMessage}
              </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Resubscribe;